#lottery {
	.eliza {
		position: absolute;
		bottom: -200px;
		left: 60px;
		width: 450px;
	}

	.logo-ocb {
		position: absolute;
		width: 220px;
		bottom: 50px;
		right: 50px;
		z-index: 1;
	}
}
